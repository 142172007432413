<template>
	<VOverlay
		:title="$t('method.newMethod.title')"
		:size="2"
		:isVCentered="false"
		:closeOnBackgroundClick="true"
		:hasBackground="true"
		:modelValue="true"
		@update:modelValue="overlayClosed"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="submit"
	>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('method.newMethod.whatName')" />

		<VInputString
			:isRequired="true"
			v-model:valueModel="methodNameValue"
			:label="$t('method.newMethod.nameInputLabel')"
			:trim="true"
			:trimFunction="trimMethodName"
			@update:valueModel="markModified"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('method.newMethod.giveDescription')" />

		<VTextarea
			v-model="methodDescription"
			:placeholder="$t('method.newMethod.giveDescription')"
			class="has-margin-bottom-4"
			:isNarrow="true"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('method.newMethod.whatConnection')" />

		<VField
			:label="$t('method.newMethod.selectConnection')"
			:isFloating="true"
			:class="{ 'is-active': connValue }"
		>
			<VSelect :options="connOptions" v-model="connValue" />
		</VField>

		<template #footer>
			<VButton
				:text="$t('method.newMethod.submitButton')"
				icon="chevron-right"
				:iconOnRight="true"
				classes="button-modal-footer"
				:isDisabled="!isValid"
				@clicked="submit"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_CONNECTIONS } from '@assets/scripts/api/config';
import {
	trimMethodName
} from '@modules/MethodBuilder/components/method';

export default {
	name: 'NewMethod',
	data: function () {
		return {
			trimMethodName,
			connOptions: [],
			connValue: false,
			methodNameValue: '',
			methodDescription: '',
		};
	},
	computed: {
		...mapGetters({
			currentConnection: getStoreGetter('CURRENT_CONNECTION', 'METHOD'),
			modified: getStoreGetter('MODIFIED', 'METHOD'),
		}),
		isValid: function () {
			return this.connValue && this.methodNameValue.trim();
		},
	},
	mounted: async function () {
		// get all available connection
		const connections = await useApiAsync(GET_CONNECTIONS);

		// format connections to use in VSelect component
		if (connections.length > 0) {
			this.connOptions = Helpers.mapObjectArrayForSelect(connections, {
				key: 'conn_guid',
				text: 'name',
			});
		}

		if (this.currentConnection) this.connValue = this.currentConnection;
	},
	methods: {
		markModified: function () {
			// no need to trigger this mutation more than once
			if (this.modified) return;

			// mark method as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'METHOD')
			);
		},
		overlayClosed: function () {
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'METHOD'),
				false
			);
		},
		submit: function () {
			if (!this.isValid) return;

			this.$store.dispatch(getStoreAction('NEW_METHOD', 'METHOD'), {
				name: this.methodNameValue.trim(),
				description: this.methodDescription.trim(),
				conn_guid: this.connValue,
			});
		},
	},
};
</script>
