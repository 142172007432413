<template>
	<div class="app-layer" :class="{ 'is-active': showMethodDetails }">
		<Suspense>
			<TheMethodDetails v-if="showMethodDetails" />
		</Suspense>
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': !showMethodDetails }"
	>
		<NewMethod v-if="showNewMethodModal" />

		<Suspense>
			<TheMethodOverview v-show="showOverview" />
		</Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import TheMethodDetails from '@modules/MethodBuilder/materials/structures/TheMethodDetails.vue';
import TheMethodOverview from '@modules/MethodBuilder/materials/TheMethodOverview.vue';

import NewMethod from '@modules/MethodBuilder/materials/structures/NewMethod.vue';

export default {
	name: 'TheMethodBuilder',
	components: {
		TheMethodDetails,
		TheMethodOverview,
		NewMethod,
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new method should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'METHOD'),
			/**
			 * Boolean to indicate whether a method is
			 * currently active
			 */
			methodActive: getStoreGetter('METHOD_ACTIVE', 'METHOD'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can create methods
		 */
		canEditMethod: function () {
			return usePermission('Upsert', 'MethodBuilder');
		},
		/**
		 * Boolean to indicate whether the current
		 * user can read (view) methods
		 */
		canReadMethod: function () {
			return usePermission('Read', 'MethodBuilder');
		},
		showNewMethodModal: function () {
			return this.showCreateNew && this.canEditMethod;
		},
		showMethodDetails: function () {
			return !this.showNewMethodModal && this.methodActive && this.canReadMethod;
		},
		showOverview: function () {
			return !this.showNewMethodModal && !this.showMethodDetails && this.canReadMethod;
		}
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'METHOD'));
	},
};
</script>
