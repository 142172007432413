<template>
	<div class="page-with-options-wrapper">
		<TheAppOptions
			:backButtonText="$t('method.methodDetails.backToOverview')"
			:backClickedFn="closeMethod"
			:nameLabel="$t('method.methodDetails.methodName')"
			v-model:name="methodName"
			@update:name="updateName"
			:saveClickedFn="saveMethod"
			:editMode="canBeEdited"
			:showButtons="canBeEdited"
			:canBeSaved="canBeSaved"
			:canBePublished="canBePublished"
			:publishClickedFn="publishMethod"
			:canBeValidated="canBeValidated"
			:validateClickedFn="validateMethod"
			:validationErrorListItemIsClickable="false"
			:trimName="true"
			:trimFunction="trimMethodName"
		/>

		<div class="page-content js-page-content">
			<ContentBlock
				:size="3"
				:title="$t('method.methodDetails.methodSettings')"
				:hasBorderUnderTitle="true"
			>
				<div class="has-padding-top-1">
					<ConfigOptions 
						:options="configOptions"
						:disabled="!canBeEdited"
						v-model:valueModel="configModel"
						@update:valueModel="configChanged"
					/>
					<VField
						:isFloating="true"
						:label="$t('method.methodDetails.settings.storedProcedure')"
						:class="{ 'is-active': true }"
					>
						<VSelect
							:options="spOptions"
							v-model="method.script"
							@update:modelValue="getSpParamsAndSetMethodInputs"
							:disabled="!canBeEdited"
						/>
					</VField>
				</div>
				<div class="has-padding-top-1">
					<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('method.methodDetails.description')" />

					<VTextarea
						v-model="method.description"
						:placeholder="$t('method.methodDetails.description')"
						class="has-margin-bottom-4"
						:isNarrow="true"
						@change="markAsUpdated"
						:disabled="!canBeEdited"
					/>
				</div>
			</ContentBlock>

			<ContentBlock
				:size="3"
				:title="$t('general.input')"
				:hasFullwidthBody="true"
			>
				<div class="is-flex has-bottom-divider has-padding-left-5 has-padding-right-5 has-margin-bottom-075">
					<VLink
						:text="$t('method.methodDetails.refreshFields')"
						icon="refresh"
						:hasUnderline="true"
						@click.prevent.stop="getSpParamsAndSetMethodInputs"
						class="has-margin-bottom-05 has-margin-right-3"
						:isDisabled="!canBeEdited || !method.script"
						:tooltipText="$t('method.methodDetails.refreshButtonToolTip')"
					/>

					<VSearch v-model="search" />
				</div>

				<SortableDataTable
					:scrollable="false"
					:stickyHeader="false"
					:rowClickedFn="inspectClicked"
					defaultSort="field_name"
					:data="filteredData"
					tableClasses="scrollable-content"
					:columns="inputColumns"
					:emptyText="$t('method.methodDetails.table.noResults')"
				/>
			</ContentBlock>

			<ContentBlock
				:size="3"
				:title="$t('general.output')"
				:hasFullwidthBody="true"
			>
				<div class="is-flex is-align-items-center has-bottom-divider has-padding-left-5 has-padding-right-5 has-margin-bottom-075">
					<VLink
						:text="$t('method.methodDetails.addField')"
						icon="plus"
						:hasUnderline="true"
						@click.prevent.stop="() => addNewClicked('output')"
						class="has-margin-bottom-05 has-margin-right-3"
						:isDisabled="!canBeEdited"
					/>

					<VOption
						v-model:modelValue="method.full_output"
						type="checkbox"
						:label="$t('method.methodDetails.fullOutput')"
						wrapperClasses="has-margin-0 has-margin-bottom-05 has-margin-right-05"
						@update:modelValue="markAsUpdated"
						:disabled="!canBeEdited"
					/>
					<VTooltip
						:text="$t('method.methodDetails.fullOutputExplanation')"
						class="has-margin-bottom-05 has-margin-right-3"
					/>

					<VSearch v-model="outputSearch" />
				</div>

				<SortableDataTable
					:scrollable="false"
					:stickyHeader="false"
					:rowClickedFn="inspectClicked"
					defaultSort="field_name"
					:data="outputFilteredData"
					tableClasses="scrollable-content"
					:columns="outputColumns"
					:emptyText="outputEmptyText"
				/>
			</ContentBlock>
		</div>
	</div>
</template>

<script>
import { mapGetters, useStore } from 'vuex';
import { reactive, toRefs } from '@vue/reactivity';
import { useI18n } from 'vue-i18n';
import Helpers from '@assets/scripts/helpers';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import TheAppOptions from '@materials/structures/TheAppOptions.vue';
import {
	formatFieldsForTable,
	trimMethodName
} from '@modules/MethodBuilder/components/method';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import { GET_SPS, GET_SP_PARAMETERS } from '@modules/MethodBuilder/endpoints';
import usePermission from '@assets/scripts/composables/usePermission';

export default {
	name: 'TheMethodDetails',
	components: {
		TheAppOptions,
	},
	data: function () {
		return {
			trimMethodName,
			methodName: '',
			search: '',
			outputSearch: '',
			// definition of output table columns
			outputColumns: [
				{
					label: this.$t('field.name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.type'),
					field: 'field_type',
					width: '15%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.validation'),
					field: 'validation',
					width: '20%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.inspectClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			],
			// definition of input table columns
			inputColumns: [
				{
					label: this.$t('field.name'),
					field: 'field_name',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.type'),
					field: 'field_type',
					width: '15%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.default_value'),
					field: 'default_value',
					width: '20%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.validation'),
					field: 'validation',
					width: '20%',
					sortable: true,
					searchable: true,
					default: this.$t('general.dash'),
				},
				{
					label: this.$t('field.required_field'),
					field: 'required_field',
					width: '15%',
					sortable: true,
					default: this.$t('general.dash'),
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.inspectClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			],
		};
	},
	setup: async function () {
		const store = useStore();
		const { t } = useI18n();

		const state = reactive({
			/**
			 * Gets currently active Method
			 */
			method: store.getters[getStoreGetter('CURRENT_METHOD', 'METHOD')],
			/**
			 * Boolean to indicate whether current method
			 * can be edited
			 */
			canBeEdited: usePermission('Upsert', 'MethodBuilder'),
			configOptions: [
				{
					value: 'is_read',
					label: t('method.methodDetails.settings.is_read'),
					info: t('method.methodDetails.settings.is_read_info'),
				},
			],
			configModel: {},
			spOptions: [],
		});

		state.configOptions.forEach((option) => {
			if (typeof state.method[option.value] !== 'undefined') {
				state.configModel[option.value] = state.method[option.value];
			}
		});

		// get all available Stored Procedures
		const sps = await useApiAsync(GET_SPS, {
			keys: {
				connection: state.method.conn_guid,
			},
			parameters: {
				exclude: true,
			}
		});

		if (state.method.script) {
			// add stored procedure from current method
			// to list of available procedures
			sps.push(state.method.script);
		}

		// sort list alphabetically
		sps.sort((a, b) => a.localeCompare(b));

		// add all available stored procedures to select field
		sps.forEach((sp) => {
			state.spOptions.push({
				value: sp,
				text: sp,
			})
		});

		return {
			...toRefs(state),
		};
	},
	mounted: function () {
		this.methodName =
			this.method && this.method.name
				? this.method.name
				: '';

		// clear validation when method is loded
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));
	},
	computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether user modified the method
			 */
			modified: getStoreGetter('MODIFIED', 'METHOD'),
			/**
			 * Boolean to indicate whether current method
			 * can be saved
			 */
			canBeSaved: getStoreGetter('CAN_BE_SAVED', 'METHOD'),
			/**
			 * Boolean to indicate whether current method
			 * can be published
			 */
			canBePublished: getStoreGetter('CAN_BE_PUBLISHED', 'METHOD'),
		}),
		canBeValidated: function () {
			return this.canBeEdited || this.canBePublished;
		},
		tableFields: function () {
			if(this.method.script) {
				return formatFieldsForTable(this.method.input, 'input', this.method);
			}
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			const matches = Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);

			return matches;
		},
		outputTableFields: function () {
			return formatFieldsForTable(this.method.output, 'output', this.method);
		},
		outputFilteredData: function () {
			if (this.method.full_output) return [];

			// filter on search string
			const matches = Helpers.filterByString(
				this.outputTableFields,
				this.outputSearch,
				this.searchableCols
			);

			return matches;
		},
		outputEmptyText: function () {
			return this.method.full_output ? 
				this.$t('method.methodDetails.fullOutputOn') :
				this.$t('method.methodDetails.table.noResults');
		}
	},
	methods: {
		markAsUpdated: function () {
			// mark method as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_MODIFIED', 'METHOD')
			);
		},
		configChanged: function () {
			Object.assign(this.method, this.configModel);
			this.markAsUpdated();
		},
		inspectClicked({ key, type = 'input' }) {
			// open drawer with field information
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'methodFieldDetails',
				data: {
					key: key + 1, // add 1 to avoid issues with 0-based index
					isOutput: type === 'output',
				},
			});
		},
		addNewClicked(type = 'input') {
			// open drawer with field information of new field
			this.$store.commit(getStoreMutation('OPEN_DRAWER'), {
				type: 'methodFieldDetails',
				data: {
					isOutput: type === 'output',
				}
			});
		},
		async getSpParamsAndSetMethodInputs() {
			// get Stored Procedure
			const spParams = await useApiAsync(GET_SP_PARAMETERS, {
				keys: {
					connection: this.method.conn_guid,
					name: this.method.script
				},
			});
			this.method.input = spParams;
			this.markAsUpdated();
		},
		deleteClicked({ key, type = 'input' }) {
			// ask confirmation to delete field
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('method.methodDetails.delete.confirm.title'),
				body: this.$t('method.methodDetails.delete.confirm.body'),
				confirmButtonText: this.$t(
					'method.methodDetails.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// do delete after confirmation
					this.method[type].splice(key, 1);
					this.markAsUpdated();
				},
			});
		},
		closeMethod: function () {
			const closeOverlay = () => {
				// dispatch action to unset method
				this.$store.dispatch(getStoreAction('UNSET_METHOD', 'METHOD'));
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the Method
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('method.methodDetails.close.confirm.title'),
					body: this.$t('method.methodDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'method.methodDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		validateMethod: function () {
			// dispatch action to Validate method
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_METHOD', 'METHOD')
			);
		},
		saveMethod: function () {
			this.$store.dispatch(
				getStoreAction('SAVE_CURRENT_METHOD', 'METHOD')
			);
		},
		publishMethod: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('method.methodDetails.publish.confirm.title'),
				body: this.$t('method.methodDetails.publish.confirm.body'),
				confirmButtonText: this.$t(
					'method.methodDetails.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish method
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_METHOD', 'METHOD')
					);
				},
			});
		},
		updateName: function (newName) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'METHOD'), newName);
		},
	},
};
</script>
